import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import "../styles/cards.css"

const ProjectCard = (props) => {
  const [hover, setHover] = React.useState(false);

  function handleHover(toggle) {
    setHover(!hover);
  }

  return (
    <a href={props.link} target="_blank">
      <div
        className='project-card'
        onMouseEnter={() => handleHover()}
        onMouseLeave={() => handleHover()}  
      >
        <Img
          className={hover ? "blur" : ""}
          fluid={props.pic}
        />
        {hover && (
          <div className='hover-details'>
            <p>{props.title}</p>
          </div>
        )}
      </div>
    </a>
  )
}

export default ProjectCard