import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Header from "../components/header"
import ProjectCard from "../components/project-card"

import '../styles/global.css'
import '../styles/index.css'

function IndexPage({ data }) {

  function resizeComponent() {
    document.getElementById('top').setAttribute('style', `height: ${window.innerHeight}px`);
    document.getElementById('wrapper').setAttribute('style', `height: ${window.innerHeight}px`);
  }

  // resizing
  React.useEffect(() => {
      resizeComponent();
      window.addEventListener('resize', resizeComponent);

      return function cleanup() {
          window.removeEventListener('resize', resizeComponent);
      }
  }, []);

  return (
    <div id='wrapper' className='page-wrapper'>
      <SEO 
        title="Jon Long"
      />
      <div className='index-wrapper'>
        <div id='top'>
          <Header active={0} />
          <div className='intro'>
            <div className='space-block'></div>
            <div className='bio-container'>
              <p className='intro-bio'>I create digital</p>
              <p className='intro-bio'>experiences.</p>
            </div>
          </div>
          <div className='scroll'>scroll to see projects</div>
        </div>
        <div className='card-container'>
          {data.allStrapiProject.edges.map((document, key) => (
            <ProjectCard 
              key=        {key}
              title=      {document.node.title}  
              pic=        {document.node.image.childImageSharp.fluid}
              link=       {document.node.link}
              description={document.node.description}
              slug=       {document.node.id}
            />
          ))}

          {((data.allStrapiProject.edges.length % 3 == 1)
          || (data.allStrapiProject.edges.length % 3 == 2)) && (
            <div className='spacer2'>
              <div className='coming-soon'>
                <p>More Coming Soon!</p>
              </div>
            </div>
          )}

          {data.allStrapiProject.edges.length % 3 == 1 && (
            <div className='spacer2'>
              <div className='coming-soon'>
                
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default IndexPage;

export const pageQuery = graphql`  
  query IndexQuery {
    allStrapiProject 
    (sort: { fields: id, order: ASC })
    {
      edges {
        node {
          id
          link
          image {
            childImageSharp {
              fluid(maxWidth: 999) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
        }
      }
    }
  }
`